import {
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands,
    tokens,
    useTableColumnSizing_unstable,
    TableColumnSizingOptions,
    TableColumnDefinition,
    useTableFeatures,
    createTableColumn,
    TableCellLayout,
    Spinner
} from "@fluentui/react-components"
//import { IReportResultsGridProps } from "./report-results-grid.props";
import { ITableColumn } from "@interfaces/common.interfaces";
import { cloneElement, useContext, useEffect, useState } from "react";
import { format } from 'date-fns';
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { IScreenPlaceholderProps } from "components/common/screen-placeholder/screen-placeholder.props";
import Progress from "components/common/progress/progress";
import { IReport } from "@interfaces/reports/reports-common.interfaces";
import { IActionResultProps } from "./action-result.props";
import ActionResultGrid from "../action-result-grid/action-result-grid";


const layoutStyles = makeStyles({
    mainWrapper: {
        flexGrow: 1,
        ...shorthands.padding('1em'),
    }
});

const defaultScreenPlaceHolderImage = "images/ReportPlaceholderImage.png";


export default function ActionResult<T>(props: IActionResultProps<T>) {

    const styles = layoutStyles();

    // const [report, setReport] = useState<IReport<TI, TD>>();

    // useEffect(() => {
    //     setReport(props.report);
    // }, [props.report]);


    // // Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
    // // No index signature with a parameter of type 'string' was found on type 'unknown'.ts(7053)
    // // (parameter) column: ITableColumn
    // // ACTION: I needed to set item as any: props.items.map((item: any, idx) =>
    // // TODO: Maybe remove the T here... and has the type on the interface as a type that has props on it??

    // const initialisedScreenPlaceholderProps: IScreenPlaceholderProps = props.placeholder || {
    //     imageUrl: "images/ReportPlaceholderImage.png",
    //     title: "Filter criteria",
    //     message: "Set the report criteria in the filter panel and run the report."
    // }

    // const noItemsScreenPlaceholderProps: IScreenPlaceholderProps = props.placeholder || {
    //     imageUrl: "images/EmptyReportImage.png",
    //     title: "No Items",
    //     message: props.noItemsMessage || "No items were found for this report."
    // }

    // //props.summaryComponent && cloneElement(props.summaryComponent, { report: report })

    return (
        <div className={styles.mainWrapper}>
            <ActionResultGrid />
            {/* {props.loading &&
                <Spinner labelPosition="below" label={ppa.t ? `${ppa.t('loading')}...` : ''} />}
            {!props.loading &&
                <>
                    {props.requestStatus === -1 && !props.loading &&
                        <ScreenPlaceholder {...initialisedScreenPlaceholderProps} />}
                    {props.requestStatus > -1 && !report?.items &&
                        <ReportProgress status={props.requestStatus} percentageComplete={props.percentageComplete} />}
                    {props.requestStatus === 3 && report &&
                        <>
                            {props.view === 'summary' &&
                                <ReportResultsSummary
                                    report={report}
                                    summaryComponent={props.summaryComponent} />}
                            {props.view === 'items' &&
                                <ReportResultsGrid
                                    columns={props.columns}
                                    columnOptions={props.columnOptions}
                                    placeholder={noItemsScreenPlaceholderProps}
                                    report={report}
                                    noItemsMessage={props.noItemsMessage}
                                    onSelectionChanged={props.onSelectionChanged} />}
                        </>}
                </>} */}
        </div>
    );
}