import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ILinkCellProps } from "./link-cell.props";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function LinkCell(props: ILinkCellProps) {
    const styles = layoutStyles();

    return (<div className={styles.mainWrapper}>
        <Link href={props.url} appearance="subtle" target="_blank">{props.label}</Link>
    </div>);
}