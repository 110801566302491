import { Button, Caption1, Card, Text, CardHeader, makeStyles, shorthands, tokens, Avatar } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
//import { IPrincipalPickerItemProps } from "./principal-picker-item.props";
import { useState } from "react";
import { IPrincipalCardProps } from "./principal-card.props";


const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.padding('5px'),
        display: 'flex'
    },
    image: {
        width: '28px'
    },
    textWrapper: {
        //whiteSpace: 'nowrap',
        //textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden'),
        //height: '1.2em',
        display: 'inline-grid',
        //width: '50%'
        //overflow: 'hidden'
        '> span': {
            display: 'block',
            ...shorthands.overflow('hidden'),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    }
});

export default function PrincipalCard(props: IPrincipalCardProps) {
    const styles = layoutStyles();

    // TODO: Need a way to get the image url from the graph api. 
    //const [imageUrl, setImageUrl] = useState<string>("https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/KatriAthokas.jpg");
    const [imageUrl, setImageUrl] = useState<string>("");

    const onClick = () => {
        console.log(`PrincipalCard -> onClick -> `, props.item);
        if (props.onSelect)
            props.onSelect(props.item);
    }

    const onDismissClick = () => {
        console.log(`PrincipalPickerItem -> onDismissClick -> `, props.item);
        if (props.onDismissClick)
            props.onDismissClick(props.item);
    }

    return (
        <Card onClick={props.onSelect ? onClick : undefined} appearance={props.style}>
            <CardHeader
                image={<Avatar
                    name={props.item.displayName}
                    image={{ src: imageUrl }} />}
                header={<div className={styles.textWrapper}><Text as="span">{props.item.displayName}</Text></div>}
                description={<div className={styles.textWrapper}><Caption1 as="span">{props.item.email || props.item.upn}</Caption1></div>}
                action={props.onDismissClick && <Button onClick={onDismissClick} appearance="subtle" icon={<DismissRegular />} />}
            />
        </Card>
        // <Card onClick={props.onSelect ? onClick : undefined} appearance={props.isResultItem ? "subtle" : "filled"}>
        //     <CardHeader
        //         image={<Avatar
        //             name={props.item.displayName}
        //             image={{ src: imageUrl }} />}
        //         //image={<img className={styles.image} src="images/SharePointLogo.svg" />}
        //         //image={<img src="images/VivaEngageLogo.png" />}
        //         //
        //         //image={<img src="https://raw.githubusercontent.com/microsoft/fluentui/master/packages/react-components/react-card/stories/assets/xlsx.png" />}
        //         header={<div className={styles.textWrapper}><Text as="span">{props.item.displayName}</Text></div>}
        //         description={<div className={styles.textWrapper}><Caption1 as="span">{props.item.email}</Caption1></div>}
        //         action={props.onActionClick && <Button onClick={onActionClick} appearance="subtle" icon={<DismissRegular />} />}
        //     />
        // </Card>
    );
}