//import navItems from "lib/nav-items";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
//import CommandBar from "@components/command-bar/command-bar";
import CommandBar from "../../../command-bar/command-bar";
import ReportResultsGrid from "../../../reports-common/report-results-grid/report-results-grid";
import ReportInput from "../../../reports-common/report-input/report-input";
import { AxiosError } from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { Button, Input, InputProps, Label, TableColumnDefinition, createTableColumn, useId } from "@fluentui/react-components";
import { TeamsFxContext } from "context/teams-context";
import { IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import {
  InfoRegular,
  DocumentRegular
} from "@fluentui/react-icons";
import { ICommandBarItem, ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import WorkspacePicker from "../../../controls/workspace-picker/workspace-picker";
import { IWorkspacePickerProps } from "../../../controls/workspace-picker/workspace-picker.props";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import InvitationsCell from "components/reports-common/report-results-grid-cells/intivations-cell/invitations-cell";
import EnumCell from "components/reports-common/report-results-grid-cells/enum-cell/enum-cell";
import IconCell from "components/reports-common/report-results-grid-cells/icon-cell/icon-cell";
import {
  ISharedLinksInWorkspaceReportDetails,
  ISharedLinksInWorkspaceReportItem
} from "@interfaces/reports/shared-link-reports.interfaces";
import IconWithLabel from "components/reports-common/report-results-grid-cells/common/icon-with-label/icon-with-label";
import SharingLinkPermissionsTypeCell from "components/reports-common/report-results-grid-cells/shared-links/sharing-link-permissions-type-cell/sharing-link-permissions-type-cell";
import LinkCell from "components/reports-common/report-results-grid-cells/link-cell/link-cell";
import { escapeForExport } from "lib/utils";
import {
  getSharingLinkPermissionsTypePrimaryLabelKey,
  getSharingLinkPermissionsTypeSecondaryLabelKey
} from "lib/shared-links";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/sharing-links-in-workspace";

export default function SharingLinksInWorkspaceReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});
  const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<ISharedLinksInWorkspaceReportItem>> = [
    {
      key: "itemName", label: t("ppaItem"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: ISharedLinksInWorkspaceReportItem) => (<LinkCell label={item.itemName} url={item.itemUrl} />)
    },
    { key: "itemUrl", label: t("ppaItemUrl"), showInGrid: false, showInExport: true, minWidth: 100 },
    { key: "linkUrl", label: t("ppaLinkUrl"), showInGrid: false, showInExport: true, minWidth: 100, idealWidth: 300 },
    { key: "itemUniqueId", label: t("ppaItemUniqueId"), showInGrid: false, showInExport: true, minWidth: 100 },
    { key: "shareId", label: t("ppaShareId"), showInGrid: false, showInExport: true, minWidth: 100 },
    {
      key: "sharedBy", label: t("ppaSharedBy"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: any) => (<PrincipalsCell columnId={key} item={item} />),
      getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
        return escapeForExport(item[key]);
      }
    },
    {
      key: "sharedBy", label: t("ppaSharedByUpn"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.sharedBy.upn;
      }
    },
    {
      key: "shared", label: t("ppaSharedOn"), showInGrid: true, showInExport: true,
      renderCell: (key: string, item: ISharedLinksInWorkspaceReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
    },
    {
      key: "permissionType", label: t("ppaPermissions"), showInGrid: true, showInExport: true, defaultWidth: 10,
      renderCell: (key: string, item: any) => (<SharingLinkPermissionsTypeCell permissionType={item.permissionType} />),
      getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
        return `"${t(getSharingLinkPermissionsTypePrimaryLabelKey(item[key]))} - ${t(getSharingLinkPermissionsTypeSecondaryLabelKey(item[key]))}"`;
      }
    },
    {
      key: "invitations", label: t("ppaInvitees"), showInGrid: true, showInExport: false, minWidth: 100,
      renderCell: (key: string, item: any) => (<InvitationsCell columnId={key} item={item} />)
    },
    {
      key: "invitations", label: t("ppaInvitations"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
        return (item[key] as Array<any>)?.length === 0 ?
          "" :
          escapeForExport(item[key]);
      }
    },
    {
      key: "invitations", label: t("ppaInviteeUpns"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.invitations.map((invitation) => { return invitation.invitee.upn || invitation.invitee.email }).join(';');
      }
    },
    {
      key: "linkMembers", label: t("ppaMembers"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: any) => (<PrincipalsCell columnId={key} item={item} />),
      getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
        return (item[key] as Array<any>)?.length === 0 ?
          "" :
          escapeForExport(item[key]);
      }
    },
    {
      key: "linkMembers", label: t("ppaMemberUpns"), showInGrid: false, showInExport: true,
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => { // TODO: 
        return item.linkMembers.map((member) => { return member.upn }).join(';');
      }
    },
    {
      key: "expiration", label: t("ppaExpires"), showInGrid: true, showInExport: true,
      renderCell: (key: string, item: ISharedLinksInWorkspaceReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />),
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.expiration ? item.expiration : "";
      }
    },
    {
      key: "requiresPassword", label: t("ppaRequiresPassword"), showInGrid: false, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: ISharedLinksInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.requiresPassword ? t("yes") : t("no");
      }
    },
    {
      key: "passwordLastModified", label: t("ppaPasswordLastModified"), showInGrid: false, showInExport: true, minWidth: 100,
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.passwordLastModified ? item.passwordLastModified : "";
      }
    },
    {
      key: "allowsAnonymousAccess", label: t("ppaAnonymousAccess"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: ISharedLinksInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.allowsAnonymousAccess ? t("yes") : t("no");
      }
    },
    {
      key: "hasExternalGuestInvitees", label: t("ppaHasGuests"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: ISharedLinksInWorkspaceReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: ISharedLinksInWorkspaceReportItem) => {
        return item.hasExternalGuestInvitees ? t("yes") : t("no");
      }
    },
  ];

  const onWorkspaceChange = (value?: IWorkspace) => {
    console.log(`SharingLinksInWorkspaceReport -> onWorkspaceChange -> `, value);
    setModel({ ...model, workspace: { siteUrl: value?.siteUrl } });
    setSelectedWorkspace(value);
  }

  const isModelValid = (): boolean => {
    return model.workspace?.siteUrl ? true : false;
  }

  const reportSectionProps: IReportSectionProps<ISharedLinksInWorkspaceReportItem, ISharedLinksInWorkspaceReportDetails> = {
    columns: cols,
    summaryComponent: <></>,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaSharingLinksInWorkspaceReportNoItemsMessage"),
    defaultExportFilenamePrefix: "ppaSharingLinksInWorkspaceReportDefaultExportFilenamePrefix"
  }

  return (
    <ReportSection {...reportSectionProps}>
      <WorkspacePicker onChange={onWorkspaceChange} model={selectedWorkspace} />
    </ReportSection>
  );
}
