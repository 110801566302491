import {
    FluentIcon,
    QuestionCircleRegular,
    SendRegular,
    CheckmarkCircleRegular,
    ArrowClockwiseDashesRegular,
    SaveRegular,
    WarningRegular
} from "@fluentui/react-icons";
import { ILocaleStrings } from "loc/locale.interfaces";


// public enum ActionRequestStatus
// {
//     Pending = 0,
//     Processing = 1,
//     Saving = 2,
//     Complete = 3,
//     Failed = 4
// }

export const getActionRequestStatusKey = (status: number): keyof ILocaleStrings => {
    switch (status) {
        case 0:
            return "ppaActionRequestStatus0";
        case 1:
            return "ppaActionRequestStatus1";
        case 2:
            return "ppaActionRequestStatus2";
        case 3:
            return "ppaActionRequestStatus3";
        case 4:
            return "ppaActionRequestStatus4";
        default:
            return "unknown"
    }
}

export const getActionRequestStatusIcon = (id: number): FluentIcon => {
    switch (id) {
        case 0:
            return SendRegular;
        case 1:
            return ArrowClockwiseDashesRegular;
        case 2:
            return SaveRegular;
        case 3:
            return CheckmarkCircleRegular;
        case 4:
            return WarningRegular;
        default:
            return QuestionCircleRegular;
    }
};

