import config from "lib/config";
import { AxiosInstance, BearerTokenAuthProvider, TeamsUserCredential, createApiClient } from "@microsoft/teamsfx";
import axios, { AxiosError } from "axios";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useTeams } from "@microsoft/teamsfx-react";
import { ISignup } from "@interfaces/signup.interfaces";
import { ITenant } from "@interfaces/tenant.interfaces";
import { differenceInSeconds, parseISO } from "date-fns";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "./l10n-context";

export interface IPPAContext {
    //signup: ISignup | null;
    //tenant: ITenant | null;
    //loadedSignup: boolean;
    //reloadSignup: () => void;
    reloadSignup: () => Promise<ISignup | null>;
    ssoError: string;
    initialising: boolean;
    disabled: boolean;
    licenceExpired: boolean;
    hasAccess: boolean;
    isSignupComplete: boolean;
    appInstanceApiUrl: string;
    licenceExpiry: string;
    getApiClient: () => AxiosInstance;
    getSignupClient: () => AxiosInstance;
    //t: (key: string, parameters?: any) => string; // TODO: IP13nParameters
    //getReportPageUrl: (reportType: string) => string;
    //getActionResultPageUrl: (actionType: number) => string;
}

export interface IPPAProviderProps {
    teamsUserCredential?: TeamsUserCredential;
    setInitialising: (initialising: boolean) => void;
    //setDisabled: (disabled: boolean) => void;
}

export const PPAContext = createContext<Partial<IPPAContext>>({});

//
export function usePPA(): IPPAContext {
    const ctx = useContext(PPAContext);

    if (ctx === undefined) {
        // This should only really be a developer error if this hook is not used inside the provider.
        throw new Error("usePPA can only be used in a PPAProvider tree");
    }

    return ctx as IPPAContext;
}

export default function PPAProvider(props: PropsWithChildren<IPPAProviderProps>) {

    const [{ context }] = useTeams();
    const { localeCode } = useL10n<ILocaleStrings>();

    const [signup, setSignup] = useState<ISignup | null>(null);
    const [tenant, setTenant] = useState<ITenant | null>(null);
    //const [loadedSignup, setLoadedSignup] = useState<boolean>(false);
    const [reloadSignup, setReloadSignup] = useState<string>();

    const [ssoError, setSsoError] = useState<string>("");

    // setInitialising
    const [isInitialising, setIsInitialising] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isLicenceExpired, setIsLicenceExpired] = useState<boolean>(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [isSignupComplete, setIsSignupComplete] = useState<boolean>(false);
    const [appInstanceApiUrl, setAppInstanceApiUrl] = useState<string>("");
    const [licenceExpiry, setLicenceExpiry] = useState<string>("");

    const setInitialising = (initialising: boolean) => {
        setIsInitialising(initialising);
        props.setInitialising(initialising);
    }

    // Create the api client for each of the api calls.
    const getAxiosClient = (apiUrl: string): AxiosInstance => {
        //
        const apiClient = createApiClient(
            apiUrl,
            // TODO: Put the try catch around the async call??
            new BearerTokenAuthProvider(async () => {
                try {
                    const accessToken = await props.teamsUserCredential?.getToken("");
                    setSsoError("");
                    return accessToken ? accessToken.token : "";
                }
                catch (error: unknown) {
                    console.log(`PPAProvider -> getApiClient ->`, error);
                    const typedError = error as any;

                    if (typedError.message) {
                        console.log(`error.message ->`, typedError.message);
                        setSsoError(typedError.message);
                    }
                    else {
                        setSsoError(JSON.stringify(error));
                    }

                    //throw error;
                    return "";
                }
            })
        );
        //
        apiClient.defaults.headers.common["Accept-Language"] = localeCode;

        return apiClient;
    };

    const getSignupClient = (): AxiosInstance => {
        return getAxiosClient(config.apiUrl || "");
    }

    const getApiClient = (): AxiosInstance => {
        return getAxiosClient(signup?.appInstance.apiUrl || "");
    }

    const getSignup = async (): Promise<ISignup | null> => {
        try {
            console.log(`PPAProvider -> getSignup`);
            const response = await getSignupClient().get<ISignup>(`/api/signups/${context?.user?.tenant?.id}`);
            console.log(`PPAProvider -> getSignup ->`, response);
            setSignup(response.data);
            //setLoadedSignup(true);
            return response.data;
        }
        catch (ex: unknown | AxiosError<string>) {
            console.log(`PPAProvider -> getSignup -> error ->`, ex);
            //setLoadedSignup(true);
            if (axios.isAxiosError(ex)) {
                console.log(`Index -> getSignup error -> Axios Error`, ex.code, ex.response?.status);
                //   setLoadErrorCode(ex.code);
                if (ex.response?.status === 404) {
                    console.log(`Index -> getSignup error -> 404`);

                }
                else {
                    // TODO: Initialising error...
                }
            }

            setInitialising(false);
            return null;
        }
    }

    // const getLocale = async (): Promise<ILocale | null> => {
    //     try {
    //         console.log(`PPAProvider -> getLocale`);
    //         const response = await getApiClient(config.apiUrl || "").get<any>(`/api/l10n/en-gb`);
    //         console.log(`PPAProvider -> getLocale ->`, response.data);

    //         return response.data;
    //     }
    //     catch (ex: unknown | AxiosError<string>) {
    //         console.log(`PPAProvider -> ILocale -> error ->`, ex);
    //         return null;
    //     }
    // }

    useEffect(() => {
        if (props.teamsUserCredential && context?.user?.tenant) {
            try {
                console.log(`PPAProvider -> useEffect -> context.app -> locale ->`, context.app?.locale);
                //console.log(`PPAProvider -> useEffect -> context ->`, context);
                getSignup();
                // TODO: Get the locale here...
                //getLocale();
            }
            catch (ex: unknown | AxiosError<string>) {
                console.log(`PPAProvider -> useEffect -> getSignup -> error ->`, ex);
            }
        }
    }, [props.teamsUserCredential, context?.user?.tenant, reloadSignup]);

    useEffect(() => {
        if (signup) {
            const getTenant = async () => {
                try {
                    console.log(`PPAProvider -> useEffect[signup] -> getTenant`);
                    const response = await getApiClient().get<ITenant>(`/api/tenants/${signup.m365TenantId}`);
                    console.log(`PPAProvider -> useEffect[signup] -> getTenant ->`, response);
                    setTenant(response.data);
                    setInitialising(false);

                    //setSsoError("Temp error...");
                }
                catch (ex: unknown | AxiosError<string>) {
                    console.log(`PPAProvider -> useEffect[signup] -> getTenant -> error ->`, ex);

                    // TODO: Initialising Error
                    setInitialising(false);
                }
            }
            if (signup.enabled) {
                getTenant();
            }
            else {
                console.log(`PPAContext -> usEffect[signup] -> signup disabled ->`, signup);
                setInitialising(false);
            }
        }
    }, [signup]);

    // TODO: useEffect to set hasAccess and inc signup.isSignupComplete...

    useEffect(() => {
        console.log(`PPAContext -> usEffect[signup, tenant] ->`, signup, tenant);
        if (signup) {
            setIsDisabled(!signup.enabled);
            const expiry = parseISO(signup.licenceAbsoluteExpiry);
            setIsLicenceExpired(differenceInSeconds(expiry, new Date()) < 0);
            setLicenceExpiry(signup.licenceAbsoluteExpiry);
            setIsSignupComplete(signup.isSignupComplete);
            if (signup.appInstance) {
                setAppInstanceApiUrl(signup.appInstance.apiUrl);
            }
        }
        else {

        }
    }, [signup, tenant]);

    useEffect(() => {
        setHasAccess(!isDisabled && !isLicenceExpired);
    }, [isDisabled, isLicenceExpired]);

    const tempWrapper: IPPAContext = {
        //signup: signup,
        //tenant: tenant,
        //loadedSignup: loadedSignup,
        //reloadSignup: () => { console.log(`reloadSignup -> `); setReloadSignup(new Date().toISOString()) },
        reloadSignup: getSignup,
        ssoError: ssoError,
        initialising: isInitialising,
        disabled: isDisabled,
        licenceExpired: isLicenceExpired,
        hasAccess: hasAccess,
        isSignupComplete: isSignupComplete,
        appInstanceApiUrl: appInstanceApiUrl,
        licenceExpiry: licenceExpiry,

        getApiClient: getApiClient,
        getSignupClient: getSignupClient
        //t: translate,
        //getReportPageUrl: getReportPageUrl,
        //getActionResultPageUrl: getActionResultPageUrl
    };

    return (
        <PPAContext.Provider value={tempWrapper}>{props.children}</PPAContext.Provider>
    )
}