import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";

// TODO: Fix this path!!
//import PageLayout from "@components/...";
import PageLayout from "../../layout/page-layout";
import Breadcrumb from "components/navigation/breadcrumb/breadcrumb";

export default function ReportRequestsWrapper() {

    return (
        <PageLayout>
            <Outlet />
        </PageLayout>
    );
}