import { PropsWithChildren, useState } from "react";
import { Button, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ISignupAppInstancesProps } from "./signup-app-instances.props";
import { IAppInstance } from "@interfaces/appInstance.interfaces";
import SignupAppInstance from "./signup-app-instance";
import { ISignup } from "@interfaces/signup.interfaces";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.margin('0', '2em')
    }
});

export default function SignupAppInstances(props: ISignupAppInstancesProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const setSubmitting = (submitting: boolean): void => {
        setIsSubmitting(submitting);
    }

    return (
        <div className={styles.mainWrapper}>
            {props.appInstances.map((appInstance: IAppInstance, idx: number) => (
                <SignupAppInstance
                    key={idx}
                    appInstance={appInstance}
                    onSelect={props.onSelect}
                    setSubmitting={setSubmitting}
                    disabled={isSubmitting} />
            ))}
            <Button onClick={props.onCancel}>{t("cancel")}</Button>
        </div>
    );
}