import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { PropsWithChildren } from "react";
import { ICommandBarGroupProps } from "./command-bar-group.props";
import ExportReportButton from "./export-report-button/export-report-button";


const layoutStyles = makeStyles({
    mainWrapper: {
        display: "inline-flex",
        alignItems: "center"
    }
});

export default function CommandBarGroup(props: ICommandBarGroupProps) {
    const styles = layoutStyles();

    return (
        <ToolbarGroup className={styles.mainWrapper}>
            {props.items?.sort((a, b) => a.order - b.order).map((item, idx) => {
                if (item.hidden)
                    return <></>

                switch (item.type) {
                    case 'component':
                        return <div key={item.id}>{item.component}</div>;
                    case 'button':
                        return <ToolbarButton disabled={item.disabled} key={item.id} icon={item.icon} onClick={item.onClick} appearance="subtle">{item.label}</ToolbarButton>
                    case 'divider':
                        return <ToolbarDivider key={item.id} />
                    default:
                        return <></>
                }
            })}
        </ToolbarGroup>
    );
}