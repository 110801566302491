import { PropsWithChildren, useContext, useEffect } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ISignupActionsProps } from "./signup-actions.props";
import { ISignup, ISignupAction } from "@interfaces/signup.interfaces";
import SignupAction from "./signup-action";
import { ISignupCompleteProps } from "./signup-complete.props";
import { IScreenPlaceholderProps } from "components/common/screen-placeholder/screen-placeholder.props";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { useNavigate } from "react-router-dom";
import { usePPA } from "context/ppa-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";


export default function SignupComplete(props: ISignupCompleteProps) {

    const { t } = useL10n<ILocaleStrings>();
    const { reloadSignup, isSignupComplete } = usePPA();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            console.log(`SignupComplete -> useEffect -> timeout ->`);
            reloadSignup().then(
                (signup: ISignup | null) => {
                    console.log(`SignupComplete -> reloadSignup -> success ->`, signup);
                },
                (error: any) => {
                    console.log(`SignupComplete -> reloadSignup -> error ->`, error);
                }
            ).catch(
                (exception: any) => {
                    console.log(`SignupComplete -> reloadSignup -> catch ->`, exception);
                }
            );
        }, 2000);
    }, []);

    useEffect(() => {
        console.log(`SignupComplete -> useEffect -> isSignupComplete ->`, isSignupComplete);
        if (isSignupComplete) {
            navigate(`/`);
        }
    }, [isSignupComplete]);

    const placeholderProps: IScreenPlaceholderProps = {
        imageUrl: "images/SignupCompleteImage.png",
        title: t("ppaSignupCompleteTitle"),
        message: t("ppaSignupCompleteMessage")
    }

    return (
        <div>
            <ScreenPlaceholder {...placeholderProps} />
        </div>
    );
}