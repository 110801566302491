import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Button, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IRemoveOrphanedUsersActionResultProps } from "./remove-orphaned-users-action-result.props";
import { IActionResult } from "@interfaces/actions/actions-common.interfaces";
import { IRemoveOrphanedUsersResult, IRemovedOrphanedUser, IUserWithWorkspaces, IWorkspaceWithUsers } from "@interfaces/actions/orphaned-user-actions.interfaces";
import { IPrincipal } from "@interfaces/common.interfaces";
import RemoveOrphanedUsersActionResultCard from "./remove-orphaned-users-action-result-card";
import ActionResultNoResultCard from "components/actions-common/action-result-no-result-card/action-result-no-result-card";
import ServiceMessagesCard from "components/common/service-messages-card/service-messages-card";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.margin('1em', 0, 0, 0)
    }
});

export default function RemoveOrphanedUsersActionResult(props: IRemoveOrphanedUsersActionResultProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    //const [result, setResult] = useState<IRemoveOrphanedUsersResult>();
    const [actionResult, setActionResult] = useState<IActionResult<IRemoveOrphanedUsersResult>>();
    const [users, setUsers] = useState<Array<IUserWithWorkspaces>>([]);
    const [workspaces, setWorkspaces] = useState<Array<IWorkspaceWithUsers>>([]);
    const [view, setView] = useState<string>("user");

    useEffect(() => {
        if (props.getResult) {
            props.getResult(`/api/actions/remove-orphaned-users/${props.requestId}`).then(
                (data: IActionResult<IRemoveOrphanedUsersResult>) => {
                    console.log(`RemoveOrphanedUsersActionResult -> useEffect -> data -> `, data);
                    //props.onClose();
                    //setIsSubmitting(false);

                    setActionResult(data);
                    //setResult(data.result);
                },
                (error: any) => {
                    console.log(`RemoveOrphanedUsersActionResult -> useEffect -> error -> `, error);
                    // TODO: Show the error in an error panel...
                    // TODO: Nicer. Like a helper for getting the message from an error. Use an error interface that has code, error message, validation errors, etc...
                    // if (error.message) {
                    //     setError(error.message);
                    // }
                    // setIsSubmitting(false);
                });
        }
    }, []);

    // useEffect on result...
    // need to group by:
    // user and sites removed from
    // site and users removed
    // 3 views:
    // multiple users removed from multiple sites (we don't really support this but it is possible...)
    // single user was removed from zero or more sites (user was selected on request)
    // zero or more users were removed from a single site (site was selected on request) 
    // logic:
    // array of all unique users in the results
    // array of all unique sites in the results
    // if one of each: user view (the default)
    // if one user and multiple sites: user view
    // if multiple users and one site: site view
    // if multiple of both: multi view (multiple cards in user view)
    // <xxx-action-result-card user={user} />
    // <xxx-action-result-card workspace={} />
    // user={user} where user has .workspaces
    // workspace={workspace} where workspace has .users
    // for multi view: users.map(user) => user={user} so it uses the user view

    useEffect(() => {
        console.log(`RemoveOrphanedUsersActionResult -> useEffect -> result.removed -> `, actionResult?.result?.removed);
        const users: Array<IUserWithWorkspaces> = []; //
        const workspaces: Array<IWorkspaceWithUsers> = [];

        if (actionResult?.result?.removed) {
            actionResult.result.removed.forEach((removedUser: IRemovedOrphanedUser) => {
                console.log(`removedUser -> `, removedUser.upn);
                const userMatch = users.filter(u => u.upn === removedUser.upn);
                if (userMatch.length === 0) {
                    users.push({
                        upn: removedUser.upn,
                        objectId: removedUser.aadObjectId,
                        displayName: removedUser.displayName,
                        email: removedUser.email,
                        workspaces: [
                            {
                                siteUrl: removedUser.workspaceUrl,
                                groupId: "",
                                title: removedUser.workspaceName,
                                template: ""
                            }
                        ]
                    });
                }
                else {
                    // Update the user to add the workspace...
                    // TODO: Also check the workspace isn't already added...
                    userMatch[0].workspaces.push({
                        siteUrl: removedUser.workspaceUrl,
                        groupId: "",
                        title: removedUser.workspaceName,
                        template: ""
                    });
                }
                const workspaceMatch = workspaces.filter(w => w.siteUrl === removedUser.workspaceUrl);
                if (workspaceMatch.length === 0) {
                    workspaces.push({
                        siteUrl: removedUser.workspaceUrl,
                        groupId: "",
                        title: removedUser.workspaceName,
                        template: "",
                        users: [
                            {
                                upn: removedUser.upn,
                                objectId: removedUser.aadObjectId,
                                displayName: removedUser.displayName,
                                email: removedUser.email
                            }
                        ]
                    });
                }
                else {
                    // Update the workspace to add the user...
                    // TODO: Also check the user isn't already added...
                    workspaceMatch[0].users.push({
                        upn: removedUser.upn,
                        objectId: removedUser.aadObjectId,
                        displayName: removedUser.displayName,
                        email: removedUser.email
                    });
                }
            });

            if (actionResult.result.removed.length === 0) {
                console.log(`length is zero...`);
            }
        }
        else {
            // Clear...
        }

        setUsers(users);
        setWorkspaces(workspaces);

        console.log(`processed -> `, users, workspaces);

        // No need for this top one, just default to user view...
        if (users.length === 1 && workspaces.length >= 1) {
            // user view
            setView("user");
        }
        else if (workspaces.length === 1 && users.length >= 1) {
            // workspace view
            setView("workspace");
        }
        else if (users.length >= 1 && workspaces.length >= 1) {
            // multi view
            setView("multiple");
        }
        else if (users.length === 0 && workspaces.length === 0) {
            setView("none");
        }

    }, [actionResult]);


    // TEMP: To move when adding in the ActionResult component inbetween Action and xxxActionResult
    const [showMessages, setShowMessages] = useState<boolean>(false);
    const [showErrors, setShowErrors] = useState<boolean>(false);

    // ServiceMessagesCard

    return (
        <div className={styles.mainWrapper}>
            {!showErrors && !showMessages &&
                <>
                    {actionResult && view === "user" && users[0] &&
                        <RemoveOrphanedUsersActionResultCard
                            user={users[0]}
                            errorCount={actionResult.errorCount}
                            messages={actionResult.messages}
                            onMessagesClick={() => { console.log(`onMessagesClick ->`); setShowMessages(true); }}
                            onErrorsClick={() => { console.log(`onErrorsClick ->`); setShowErrors(true); }}
                        />}
                    {actionResult && view === "workspace" && workspaces[0] &&
                        <RemoveOrphanedUsersActionResultCard
                            workspace={workspaces[0]}
                            errorCount={actionResult.errorCount}
                            messages={actionResult.messages}
                            onMessagesClick={() => { console.log(`onMessagesClick ->`); setShowMessages(true); }}
                            onErrorsClick={() => { console.log(`onErrorsClick ->`); setShowErrors(true); }}
                        />}
                    {actionResult && view === "multiple" &&
                        <div>
                            {users.map((user, idx) => (
                                <RemoveOrphanedUsersActionResultCard
                                    user={user}
                                    errorCount={actionResult.errorCount}
                                    messages={actionResult.messages}
                                    onMessagesClick={() => { console.log(`onMessagesClick ->`); setShowMessages(true); }}
                                    onErrorsClick={() => { console.log(`onErrorsClick ->`); setShowErrors(true); }}
                                />
                            ))}
                        </div>}
                    {actionResult && view === "none" &&
                        <ActionResultNoResultCard
                            message={t('ppaRemoveOrphanedUsersActionNoResultsMessage')}
                            errorCount={actionResult.errorCount}
                            messages={actionResult.messages}
                            onMessagesClick={() => { console.log(`onMessagesClick ->`); setShowMessages(true); }}
                            onErrorsClick={() => { console.log(`onErrorsClick ->`); setShowErrors(true); }} />}
                </>}
            {actionResult && showMessages &&
                <ServiceMessagesCard
                    title={t('messages')}
                    messages={actionResult.messages}
                    onClose={() => setShowMessages(false)}
                />}
            {actionResult && showErrors &&
                <ServiceMessagesCard
                    title={t('errors')}
                    messages={actionResult.messages.filter(m => m.type === 3 || m.type === 7)}
                    onClose={() => setShowErrors(false)}
                />}
        </div>
    );
}