import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Caption1, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.padding('5em', '0', '0', '0'),
    },
});

export default function Initialising(props: any) {
    const styles = layoutStyles();
    const { t, loaded, reloadKey } = useL10n<ILocaleStrings>();
    const [label, setLabel] = useState<string>();

    useEffect(() => {
        setLabel(t("initialising"));
    }, [reloadKey]);

    return (
        <div className={styles.mainWrapper}>
            <Spinner
                labelPosition="below"
                label={label} />
        </div>
    );
}