import { PropsWithChildren } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { format, isDate, parseISO } from "date-fns";
//import { es, te } from 'date-fns/locale'; //, { locale: te }

const layoutStyles = makeStyles({
    mainWrapper: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap'
    },
});

export default function DateTimeCell(props: { columnId: string, item: { [key: string]: any }, pattern: string }) {
    const styles = layoutStyles();

    const dateTime = parseISO(props.item[props.columnId]);
    const formattedDate = isNaN(dateTime.getDate()) ? props.item[props.columnId] : format(dateTime, props.pattern);

    return (
        <div className={styles.mainWrapper}>{formattedDate}</div>
    );
}