import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { useTeams } from "@microsoft/teamsfx-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { TeamsFxContext } from "context/teams-context";
import config from "lib/config";
import axios, { AxiosError } from "axios";
import { ISignup } from "@interfaces/signup.interfaces";
import { IAppInstance } from "@interfaces/appInstance.interfaces";


// TODO: Fix this path!!
//import PageLayout from "@components/Layout/PageLayout";
import PageLayout from "../layout/page-layout";
//import navItems from "lib/nav-items";
//import NavigationCards from "../navigation/navigation-cards/navigation-cards";
//import navItemsV2 from "lib/nav-items-v2";
import { usePPA } from "context/ppa-context";
import { getTopLevelNavigationCardItems } from "lib/utils";
import NavigationCards from "components/navigation/navigation-cards/navigation-cards";
import Breadcrumb from "components/navigation/breadcrumb/breadcrumb";


// TODO: Something with this: // if (needConsent) {

export default function Index() {

  //const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
  const { hasAccess, isSignupComplete } = usePPA();
  //const [{ context }] = useTeams();
  const navigate = useNavigate();
  //const [loadErrorCode, setLoadErrorCode] = useState<string>();


  // Maybe this is if not initialising...


  useEffect(() => {
    if (hasAccess && !isSignupComplete) {
      console.log(`Index -> incomplete signup ->`);
      navigate(`/signup`);
    }
  }, [hasAccess, isSignupComplete]);

  const items = useMemo(() => {
    //console.log(`ReportsIndex -> useMemo -> `, location.pathname);
    //navItemsV2.filter(n => !n.parentId && n.showAsCard === true)
    // TODO: getTopLevelNavigationCardItemsV2();
    return getTopLevelNavigationCardItems(); //getReportNavigationCardItemsV2('');
  }, []);

  return (
    <PageLayout>
      <NavigationCards items2={items} />
    </PageLayout >
  );
}
