import { PropsWithChildren, useEffect, useState } from "react";
import { Label, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IStorageInWorkspaceReportSummaryProps } from "./storage-in-workspace-report-summary.props";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import { getSizeLabel } from "lib/utils";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";




const layoutStyles = makeStyles({
    mainWrapper: {
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1em'
    }
});

export default function StorageInWorkspaceReportSummary(props: IStorageInWorkspaceReportSummaryProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [additionalLabel, setAdditionalLabel] = useState<string>("");
    const [additionalWarningLabel, setAdditionalWarningLabel] = useState<string>("");

    useEffect(() => {
        if (props.report?.details) {
            const used = getSizeLabel(props.report.details.siteTotalSizeKB, 'KB');
            const quota = getSizeLabel(props.report.details.siteQuotaMaximumMB, 'MB');
            setAdditionalLabel(`(${t("ppaQuotaUsedValue", { used: used, quota: quota })})`);

            const warningLevel = getSizeLabel(props.report.details.storageWarningLevelMB, 'MB');
            const maxLevel = getSizeLabel(props.report.details.storageMaximumLevelMB, 'MB');
            setAdditionalWarningLabel(`(${t("ppaWarningLevelValue", { warning: warningLevel, max: maxLevel })})`);
        }

    }, [props.report?.details]);

    return (<div>
        {props.report?.details &&
            <>
                <div className={styles.section}>
                    <Label>{t("ppaPercentageOfQuota")}</Label>
                    <PercentageBar
                        value={props.report.details.percentageOfQuota}
                        valueDecimalPlaces={2}
                        showLabel={true}
                        additionalLabel={additionalLabel} />
                </div>
                <div className={styles.section}>
                    <Label>{t("ppaStorageLimits")}</Label>
                    <div>{props.report.details.isQuotaSiteSpecific ? t("ppaStorageQuotaSiteSpecificDescription") : t("ppaStorageQuotaTenantWideDescription")}</div>
                </div>
                {props.report.details.isQuotaSiteSpecific &&
                    <>
                        <Label>{t("ppaWarningLevel")}</Label>
                        <PercentageBar
                            value={props.report.details.warningLevelPercentage}
                            valueColour={tokens.colorNeutralStroke1}
                            spareColour={tokens.colorStatusWarningBackground3}
                            showLabel={true}
                            additionalLabel={additionalWarningLabel} />
                    </>
                }
            </>}
    </div >);
}