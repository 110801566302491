import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { createElement } from "react";
import UnknownActionForm from "components/actions/unknown/unknown-action-form";
import UnknownActionResult from "components/actions/unknown/unknown-action-result";
import RemoveOrphanedUsersActionForm from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-form"
import RemoveOrphanedUsersActionResult from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-result";
import { ILocaleStrings } from "loc/locale.interfaces";


export const getActionFormComponent = (actionType: number): ReactJSXElement => {
    switch (actionType) {
        case 1:
            return createElement(RemoveOrphanedUsersActionForm);
        default:
            return createElement(UnknownActionForm);
    }
}

export const getActionResultComponent = (actionType: number): ReactJSXElement => {
    switch (actionType) {
        case 1:
            return createElement(RemoveOrphanedUsersActionResult);
        default:
            return createElement(UnknownActionResult);
    }
}

// public enum ActionType
// {
//     None = 0,
//     RemoveOrphanedUsers = 1
// }

export const getActionTypeKey = (actionType: number): keyof ILocaleStrings => {
    switch (actionType) {
        case 1:
            return "ppaRemoveOrphanedUsersActionTitle";
        default:
            return "unknown";
    }
};