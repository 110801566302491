const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiUrl: process.env.REACT_APP_API_URL,
  apiName: process.env.REACT_APP_FUNC_NAME,
  aadAppRedirectUri: process.env.REACT_APP_REDIRECT_URI
};

export default config;
