import { IActionResultSectionProps } from "components/actions-common/action-result-section/action-result-section.props";
import { ITableColumn } from "@interfaces/common.interfaces";
import ActionResultSection from "components/actions-common/action-result-section/action-result-section";




const cols: Array<ITableColumn<any>> = [ // TODO: Was: IOrphanedUsersInTenantReportItem
    { key: "displayName", label: "Display name", showInGrid: true, showInExport: true, minWidth: 100 },
    { key: "upn", label: "Upn", showInGrid: true, showInExport: true },
];

export default function RemoveOrphanedUsersAction() {

    const sectionProps: IActionResultSectionProps<any> = { // TODO: WAS: IOrphanedUsersInTenantReportItem
        columns: cols,
        // summaryComponent: <OrphanedUsersInTenantReportSummary temp="the temp prop" />,
        // reportUrl: reportUrl,
        // model: model,
        // isModelValid: isModelValid(),
        // noItemsMessage: "No orphaned users found in the tenant.",
        // exportFilenamePrefix: "OrphanedUsersInTenantReport",
        // buttons: [
        //   {
        //     // order, etc...
        //     component: <RemoveOrphanedUsersActionButton />
        //   }
        // ]
    }

    return (
        <ActionResultSection {...sectionProps} />
    );
}