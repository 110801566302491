import { PropsWithChildren } from "react";
import { Body1Stronger, Caption1, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ISignupHeaderProps } from "./signup-header.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // ...shorthands.padding('0', '2em')
        ...shorthands.margin('2em')
    },
    caption: {
        fontSize: 'var(--fontSizeBase200)'
    }
});

export default function SignupHeader(props: ISignupHeaderProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            <Body1Stronger as="h1">{props.title}</Body1Stronger>
            <div className={styles.caption} dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
    );
}