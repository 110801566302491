import { makeStyles, shorthands } from "@fluentui/react-components";
import {
    InfoRegular,
    TextColumnOneWideLightningRegular,
    TextGrammarLightningRegular
} from "@fluentui/react-icons";
import { IReportResultsMessageBarProps } from "./report-results-message-bar.props";
import MessagesBar from "components/common/messages-bar/messages-bar";
import { useEffect, useState } from "react";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.padding('1em')
    }
});

export default function ReportResultsMessageBar(props: IReportResultsMessageBarProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [icon, setIcon] = useState<any>();
    const [title, setTitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [actions, setActions] = useState<Array<any>>([]);
    const [showMessageBar, setShowMessageBar] = useState<boolean>(false);

    useEffect(() => {
        if (props.actionRequestCount > 0) {
            setIcon(<TextGrammarLightningRegular />);
            setTitle("");
            const messageKey: keyof ILocaleStrings = props.actionRequestCount === 1 ?
                "ppaReportActionRequestsSingleMessage" :
                "ppaReportActionRequestsMultipleMessage";
            setMessage(t(messageKey, { actionCount: props.actionRequestCount }));
            //setActions([{ label: `View`, onClick: () => { props.onViewActionResultsClick() } }]);
            setShowMessageBar(true);
        }
        else {
            setIcon("");
            setTitle("");
            setMessage("");
            setActions([]);
            setShowMessageBar(false);
        }
    }, [props.actionRequestCount]);

    return (
        <>
            {showMessageBar &&
                <div className={styles.mainWrapper}>
                    <MessagesBar
                        icon={icon}
                        title={title}
                        message={message}
                        onDismiss={() => { setShowMessageBar(false) }}
                        actions={actions} />
                </div>}
        </>
    );
}