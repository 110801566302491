import { ILocaleStrings } from "loc/locale.interfaces";


export const getWorkspaceImageUrl = (workspaceType: number | string | undefined): string => {
    switch (workspaceType) {
        case 0:
        case "SiteCollection":
        case 1:
        case "SubSite":
        case 2:
        case "M365Group":
        case 3:
        case "Planner":
        case 4:
        case "Yammer":
            return "images/SharePointLogo.svg";
        case 5:
        case "Team":
            return "images/TeamsLogo.svg";
        case 6:
        case "PrivateChannel":
            return "images/TeamsPrivateChannelLogo.png";
        case 7:
        case "SharedChannel":
            return "images/TeamsSharedChannelLogo.png";
        default:
            return "images/SharePointLogo.svg";
    }
}

export const getWorkspaceTypeKey = (workspaceType: number | string | undefined): keyof ILocaleStrings => {
    switch (workspaceType) {
        case 0:
        case "SiteCollection":
            return "ppaWorkspaceTypeSiteCollection";
        case 1:
        case "SubSite":
            return "ppaWorkspaceTypeSubSite";
        case 2:
        case "M365Group":
            return "ppaWorkspaceTypeM365Group";
        case 3:
        case "Planner":
            return "ppaWorkspaceTypePlanner";
        case 4:
        case "Yammer":
            return "ppaWorkspaceTypeYammer";
        case 5:
        case "Team":
            return "ppaWorkspaceTypeTeam";
        case 6:
        case "PrivateChannel":
            return "ppaWorkspaceTypeTeamsPrivateChannel";
        case 7:
        case "SharedChannel":
            return "ppaWorkspaceTypeTeamsSharedChannel";
        default:
            return "unknown";
    }
}

export const getWorkspaceRoleTypeKey = (workspaceRoleType: string | undefined): keyof ILocaleStrings => {
    switch (workspaceRoleType) {
        case "Owner":
            return "ppaWorkspaceRoleTypeOwner";
        case "Member":
            return "ppaWorkspaceRoleTypeMember";
        case "Visitor":
            return "ppaWorkspaceRoleTypeVisitor";
        case "Custom":
            return "ppaWorkspaceRoleTypeCustom";
        default:
            return "unknown";
    }
}

export const getSharePointTemplateKey = (template: string): keyof ILocaleStrings => {
    switch (template) {
        case "SPSMSITEHOST#0":
            return "ppaSharePointSiteTemplatePersonalisationSite";
        case "SRCHCEN#0":
            return "ppaSharePointSiteTemplatePersonalisationSite";
        default:
            return "unknown";
    }
}