import { PropsWithChildren, useEffect, useState } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    ArrowDownloadRegular
} from "@fluentui/react-icons";
import { IExportReportButtonProps } from "./export-report-button.props";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";

const layoutStyles = makeStyles({
});

export default function ExportReportButton<T>(props: IExportReportButtonProps<T>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setDisabled((props.items && props.items.length > 0) !== true);
    }, [props.items]);

    // TODO: Cols can have a flag for showInGrid, showInExport
    // TODO: Create a filtered results array and use that to display and export...
    const downloadCsv = () => {
        const filename = t(props.defaultFilenamePrefix);
        try {
            console.log(`downloadCsv -> `, filename);
            //const jsonKeys = props.columns.map((col) => { return col.key });
            const headerData = props.columns.map((col) => { return col.label }).join(',');
            const rowData = props.items?.map((item: any) => {
                return props.columns.map((col) => {
                    if (col.getExportValue) {
                        return col.getExportValue(col.key, item);
                    }
                    else {
                        return item[col.key] ? `"${item[col.key]}"` : ``;
                    }
                }).join(',');
            });
            const json2CSV = `${headerData}\n${rowData?.join('\n')}`;
            //console.log(`test -> `, json2CSV);

            //Export our csv in rows to a csv file
            //let csvContent = "data:text/csv;charset=utf-8," + json2CSV;
            //var encodedUri = encodeURI(csvContent);
            //window.open(encodedUri);
            //window.open(csvContent);

            let csvContent = json2CSV;
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            //if (navigator.msSaveBlob) { // In case of IE 10+
            //    navigator.msSaveBlob(blob, filename);
            //} else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', `${filename}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        catch (error: any) {
            console.log(`downloadCsv -> error -> `, filename, error);
        }
    };


    return (
        <ToolbarButton
            icon={<ArrowDownloadRegular />}
            disabled={disabled}
            onClick={downloadCsv} >{t('export')}</ToolbarButton>
    );
}