import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Card, CardHeader, Text, Image, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens, Caption1, Button, Spinner } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IObjectPickerResultsHeaderProps } from "./object-picker-results-header.props";


const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.padding('0', '0.5em', '0.5em', '0.5em'),
        display: 'flex',
        alignItems: 'center',
        minWidth: '200px'
    },
    caption: {
        marginLeft: '0.25em'
    }
});

export default function ObjectPickerResultsHeader<T>(props: IObjectPickerResultsHeaderProps<T>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [label, setLabel] = useState<string>();

    useEffect(() => {
        if (props.loading) {
            setLabel(t("loading"));
        }
        else if (!props.results) {
            setLabel(t("pickerWaitingForCache"));
        }
        else if (props.results) {
            if (props.results.length === 0) {
                setLabel(t("pickerResultsNoItemsMessage"));
            }
            else {
                const labelKey: keyof ILocaleStrings = props.results.length === 1 ?
                    "pickerResultsSingleItemMessage" :
                    "pickerResultsMultipleItemsMessage"
                setLabel(t(labelKey, { resultCount: props.results.length }));
            }
        }
        else {
            setLabel("");
        }
    }, [props.loading, props.results]);

    return (
        <div className={styles.mainWrapper}>
            {props.loading && <Spinner size="extra-tiny" />}
            <Caption1 className={styles.caption}>
                {label}
            </Caption1>
        </div>
    );
}