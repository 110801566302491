//import navItems from "lib/nav-items";
import ReportProgress from "components/reports-common/report-progress/report-progress";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { format, isDate, parse, parseISO, toDate } from 'date-fns';
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { ITableColumn, IPrincipal, IWorkspace } from "@interfaces/common.interfaces";
import { IReportSectionProps } from "components/reports-common/report-section/report-section.props";
import { IGuestUserReportDetails, IGuestUserReportItem, IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import ReportSection from "components/reports-common/report-section/report-section";
import WorkspacePicker from "components/controls/workspace-picker/workspace-picker";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import PrincipalPicker from "components/controls/principal-picker/principal-picker";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import ObjectPicker from "components/controls/object-picker/object-picker";
import PrincipalCard from "components/common/principal-card/principal-card";
import { getWorkspaceRoleTypeKey, getWorkspaceTypeKey } from "lib/workspaces";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/guest-user";

export default function GuestUserReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});
  const [selectedUser, setSelectedUser] = useState<IPrincipal>();

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IGuestUserReportItem>> = [
    { key: "displayName", label: t("displayName"), showInGrid: false, showInExport: true, minWidth: 200 },
    { key: "email", label: t("email"), showInGrid: false, showInExport: true, minWidth: 100, idealWidth: 200 },
    { key: "upn", label: t("upn"), showInGrid: false, showInExport: true, minWidth: 200, idealWidth: 300 },
    { key: "objectId", label: t("entraUserId"), showInGrid: false, showInExport: true },
    { key: "memberOfName", label: t("name"), showInGrid: true, showInExport: true, minWidth: 200, idealWidth: 300 },
    { key: "memberOfUrl", label: t("url"), showInGrid: true, showInExport: true, minWidth: 200, idealWidth: 400 },
    { key: "memberOfId", label: t("id"), showInGrid: false, showInExport: true, minWidth: 100 },
    {
      key: "memberOfType", label: t("type"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: IGuestUserReportItem) => (t(getWorkspaceTypeKey(item.memberOfType))),
      getExportValue: (key: string, item: IGuestUserReportItem) => (t(getWorkspaceTypeKey(item.memberOfType)))
    }, // TODO: Maybe this is an icon column?
    //{ key: "groupType", label: "Group type", showInGrid: true, showInExport: true, minWidth: 100 },
    //{ key: "groupName", label: "Group name", showInGrid: true, showInExport: true, minWidth: 100 },
    {
      key: "memberOfRole", label: t("role"), showInGrid: true, showInExport: true, minWidth: 100,
      renderCell: (key: string, item: IGuestUserReportItem) => {
        return item.memberOfRole ? t(getWorkspaceRoleTypeKey(item.memberOfRole)) : ""
      },
      getExportValue: (key: string, item: IGuestUserReportItem) => {
        return item.memberOfRole ? t(getWorkspaceRoleTypeKey(item.memberOfRole)) : ""
      }
    },
    //{ key: "customPermission", label: "Custom permission", showInGrid: true, showInExport: true, minWidth: 150 },
    { key: "created", label: t("created"), showInGrid: false, showInExport: true, renderCell: (key: string, item: IGuestUserReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
    { key: "deleted", label: t("deleted"), showInGrid: false, showInExport: true, renderCell: (key: string, item: IGuestUserReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
    {
      key: "pendingAcceptance", label: t("pending"), showInGrid: false, showInExport: true,
      renderCell: (key: string, item: IGuestUserReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: IGuestUserReportItem) => {
        return item.pendingAcceptance ? t("yes") : t("no");
      }
    },
    {
      key: "accountDisabled", label: t("disabled"), showInGrid: false, showInExport: true,
      renderCell: (key: string, item: IGuestUserReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
      getExportValue: (key: string, item: IGuestUserReportItem) => {
        return item.accountDisabled ? t("yes") : t("no");
      }
    },
    { key: "lastLogin", label: t("lastLogin"), showInGrid: false, showInExport: false } // TODO: Only if data available/P1 licence. Maybe a flag in settings? Or check the tenant settings for P1 lic?
  ];

  const onUserChange = (value?: IPrincipal) => {
    console.log(`GuestUserReport -> onUserChange -> `, value);
    setModel({ ...model, user: { upn: value?.upn } });
    setSelectedUser(value);
  }

  const isModelValid = (): boolean => {
    return model.user?.upn ? true : false;
  }

  const reportSectionProps: IReportSectionProps<IGuestUserReportItem, IGuestUserReportDetails> = {
    columns: cols,
    summaryComponent: <></>,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaGuestUserReportNoItemsMessage"),
    defaultExportFilenamePrefix: "ppaGuestUserReportDefaultExportFilenamePrefix"
  }

  return (
    <ReportSection {...reportSectionProps}>
      <PrincipalPicker
        onChange={onUserChange}
        model={selectedUser}
        label="principalPickerGuestUserLabel"
        userType="Guest" />
    </ReportSection>
  );
}
