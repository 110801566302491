import {
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands,
    tokens,
    useTableColumnSizing_unstable,
    TableColumnSizingOptions,
    TableColumnDefinition,
    useTableFeatures,
    createTableColumn,
    TableCellLayout
} from "@fluentui/react-components"
import { IReportResultsGridProps } from "./report-results-grid.props";
import { ITableColumn } from "@interfaces/common.interfaces";
import { cloneElement, useEffect, useState } from "react";
import { format } from 'date-fns';
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { IScreenPlaceholderProps } from "components/common/screen-placeholder/screen-placeholder.props";
import Progress from "components/common/progress/progress";
import ReportProgress from "../report-progress/report-progress";
import { IReport } from "@interfaces/reports/reports-common.interfaces";
import Grid from "components/grid/grid";


const layoutStyles = makeStyles({
    mainWrapper: {
        //flexGrow: 1,
        //...shorthands.padding('1em'),
    },
    table: {
        fontSize: tokens.fontSizeBase300
    },
    tableHeaderCell: {
        fontWeight: tokens.fontWeightSemibold
    }
});


export default function ReportResultsGrid<TI, TD>(props: IReportResultsGridProps<TI, TD>) {

    const styles = layoutStyles();
    const [items, setItems] = useState<Array<TI>>([]);

    useEffect(() => {
        setItems(props.report?.items || []);
    }, [props.report]);

    const noItemsScreenPlaceholderProps: IScreenPlaceholderProps = props.placeholder || {
        imageUrl: "images/EmptyReportImage.png",
        title: "No Items",
        message: props.noItemsMessage || "No items were found for this report."
    }

    return (
        <div className={styles.mainWrapper}>
            {items.length === 0 &&
                <ScreenPlaceholder {...noItemsScreenPlaceholderProps} />}
            {items.length > 0 &&
                <Grid
                    columns={props.columns}
                    items={items}
                    details={props.report?.details}
                    selectionMode="multiselect"
                    onSelectionChanged={props.onSelectionChanged} />
            }
        </div>
    );
}