import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { INavigateCellProps } from "./navigate-cell.props";
import { useNavigate } from "react-router-dom";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function NavigateCell(props: INavigateCellProps) {
    const styles = layoutStyles();
    const navigate = useNavigate();

    return (<div className={styles.mainWrapper}>
        <Link
            onClick={() => navigate(props.url)}
            appearance="subtle">{props.label}</Link>
    </div>);
}