import {
    FluentIcon,
    QuestionCircleRegular,
    EyeRegular,
    EditRegular,
    ArrowDownloadOffRegular,
    PersonFeedbackRegular
} from "@fluentui/react-icons";
import { ILocaleStrings } from "loc/locale.interfaces";


export const getSharingLinkPermissionsTypePrimaryLabelKey = (id: number): keyof ILocaleStrings => {
    switch (id) {
        case 0:
            return "ppaSharingLinkPermissionsType0PrimaryLabel";
        case 1:
            return "ppaSharingLinkPermissionsType1PrimaryLabel";
        case 2:
            return "ppaSharingLinkPermissionsType2PrimaryLabel";
        case 3:
            return "ppaSharingLinkPermissionsType3PrimaryLabel";
        default:
            return "unknown"
    }
}

export const getSharingLinkPermissionsTypeSecondaryLabelKey = (id: number): keyof ILocaleStrings => {
    switch (id) {
        case 0:
            return "ppaSharingLinkPermissionsType0SecondaryLabel";
        case 1:
            return "ppaSharingLinkPermissionsType1SecondaryLabel";
        case 2:
            return "ppaSharingLinkPermissionsType2SecondaryLabel";
        case 3:
            return "ppaSharingLinkPermissionsType3SecondaryLabel";
        default:
            return "unknown"
    }
}

export const getSharingLinkPermissionsTypeIcon = (id: number): FluentIcon => {
    switch (id) {
        case 0:
            return EditRegular;
        case 1:
            return PersonFeedbackRegular;
        case 2:
            return EyeRegular;
        case 3:
            return ArrowDownloadOffRegular;
        default:
            return QuestionCircleRegular;
    }
};