import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

// TODO: Fix this path!!
//import PageLayout from "@components/Layout/PageLayout";
import PageLayout from "../../layout/page-layout";
import Breadcrumb from "../../navigation/breadcrumb/breadcrumb";
import { usePPA } from "context/ppa-context";
//import { TempContext } from "context/tempContext";
//import navItems from "lib/nav-items";

export default function SignupWrapper() {


    const { ssoError } = usePPA();

    return (
        <PageLayout>
            {!ssoError && <Outlet />}
        </PageLayout>
    );
}