import { PropsWithChildren } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IOrphanedUsersInTenantReportSummaryProps } from "./orphaned-users-in-tenant-report-summary.props";

const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function OrphanedUsersInTenantReportSummary(props: IOrphanedUsersInTenantReportSummaryProps) {
    const styles = layoutStyles();

    return (<div>
        {/* <div>OrphanedUsersInTenantReportDetails</div>
        <div>{props.temp}</div> */}
    </div>);
}