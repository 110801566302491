import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";


export default function SharingLinksActionsIndex() {
    return (
        <>
            <div>
                Sharing Links Actions Index
            </div>
        </>
    );
}
