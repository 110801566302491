import NavigationCards from "components/navigation/navigation-cards/navigation-cards";
import { getNavigationCardItems } from "lib/utils";
import { useContext, useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";


export default function ActionsIndex() {

    const location = useLocation();

    const items = useMemo(() => {
        console.log(`ActionsIndex -> useMemo -> `, location.pathname);
        return getNavigationCardItems(location.pathname);
    }, []);

    return (
        <NavigationCards items2={items} />
    );
}
