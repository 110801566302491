import { PropsWithChildren } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    CheckboxCheckedRegular
} from "@fluentui/react-icons";
import { format, isDate, parseISO } from "date-fns";

const layoutStyles = makeStyles({
    mainWrapper: {
        fontSize: tokens.fontSizeBase600
    }
});

export default function CheckedCell(props: { columnId: string, item: { [key: string]: any }, checkedValue: any }) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            {props.item[props.columnId] === props.checkedValue &&
                <CheckboxCheckedRegular />}
        </div>);
}