import { PropsWithChildren, useContext } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular
} from "@fluentui/react-icons";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IReportRequestsProps } from "../report-requests/report-requests.props";
import { IScreenPlaceholderProps } from "components/common/screen-placeholder/screen-placeholder.props";
import { IReportRequestsGridProps } from "./report-requests-grid.props";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import Grid from "components/grid/grid";
import { ITableColumn } from "@interfaces/common.interfaces";
import { IReportRequest } from "@interfaces/reports/reports-common.interfaces";
import IconWithLabel from "../report-results-grid-cells/common/icon-with-label/icon-with-label";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import { getReportPageUrl, getReportRequestStatusIcon, getReportRequestStatusKey, getReportTypeKey } from "lib/report-requests";
import NavigateCell from "../report-results-grid-cells/navigate-cell/navigate-cell";
import PrincipalsCell from "../report-results-grid-cells/principals-cell/principals-cell";
import DateTimeCell from "../report-results-grid-cells/date-time-cell/date-time-cell";

const layoutStyles = makeStyles({
    mainWrapper: {
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // ...shorthands.padding('0', '2em')
        flexGrow: 1,
        ...shorthands.padding(0, '1em'),
    }
});

export default function ReportRequestsGrid(props: IReportRequestsGridProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    //
    const cols: Array<ITableColumn<IReportRequest>> = [
        //{ key: "requestId", label: "Id", minWidth: 200 },
        {
            key: "reportName", label: t("ppaReportType"), minWidth: 300,
            renderCell: (key: string, item: IReportRequest) => (
                <NavigateCell
                    label={t(getReportTypeKey(item.reportType))}
                    url={getReportPageUrl(item.reportType, item.requestId)} />
            )
        },
        {
            key: "status", label: t("status"), minWidth: 100,
            renderCell: (key: string, item: IReportRequest) => (
                <IconWithLabel
                    icon={getReportRequestStatusIcon(item.status)}
                    label={t(getReportRequestStatusKey(item.status))} />
            )
        },
        {
            key: "percentageComplete", label: t("percentageComplete"), minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (
                <PercentageBar
                    value={item.percentageComplete}
                    valueDecimalPlaces={1}
                    showLabel={true} />
            )
        },
        {
            key: "requestedBy", label: t("requestedBy"), minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (<PrincipalsCell columnId={key} item={item} />)
        },
        {
            key: "requested", label: t("requested"), minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
        },
        {
            key: "generated", label: t("completed"), minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
        },
        { key: "actionRequestCount", label: t("ppaActionRequests") },
    ];

    const noItemsScreenPlaceholderProps: IScreenPlaceholderProps = {
        imageUrl: "images/EmptyReportImage.png",
        title: t("ppaNoRequestsTitle"),
        message: t("ppaNoReportRequestsMessage")
    }

    return (
        <div className={styles.mainWrapper}>
            {props.items.length === 0 &&
                <ScreenPlaceholder {...noItemsScreenPlaceholderProps} />}
            {props.items.length > 0 &&
                <Grid
                    columns={cols}
                    items={props.items}
                    selectionMode="none"
                    onSelectionChanged={props.onSelectionChanged} />}
        </div>
    );
}