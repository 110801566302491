import { PropsWithChildren } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ISignupActionsProps } from "./signup-actions.props";
import { ISignupAction } from "@interfaces/signup.interfaces";
import SignupAction from "./signup-action";

const layoutStyles = makeStyles({
    mainWrapper: {
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.margin('0', '2em')
    }
});

export default function SignupActions(props: ISignupActionsProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            {props.actions.map((signupAction: ISignupAction, idx: number) => (
                <SignupAction key={idx} action={signupAction} />
            ))}
        </div>
    );
}