import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";

// TODO: Fix this path!!
//import PageLayout from "@components/...";
import PageLayout from "../../layout/page-layout";

export default function ActionRequestsWrapper() {

    return (
        <PageLayout>
            <Outlet />
        </PageLayout>
    );
}