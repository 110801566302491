import { PropsWithChildren, useEffect, useState } from "react";
import { IReportInputProps } from "./report-input.props";
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, InlineDrawer, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    Dismiss24Regular,
    DismissRegular
} from "@fluentui/react-icons";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";


const layoutStyles = makeStyles({
    mainWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        //backgroundColor: tokens.colorNeutralBackground2
    },
    background: {
        backgroundColor: tokens.colorNeutralBackground2
    },
    contentWrapper: {
        ...shorthands.padding('1em', 0)
    },
    errorWrapper: {

    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '> button': {
            ...shorthands.margin(0, 0, 0, '1em')
        }
    }
});

export default function ReportInput<T>(props: PropsWithChildren<IReportInputProps<T>>) {

    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    const onSubmitHandler = () => {
        console.log(`onSubmitHandler -> `);
        setError("");
        setIsSubmitting(true);
        props.onSubmit().then(
            (data: any) => {
                console.log(`ReportResultsInput -> onSubmitHandler -> data -> `, data);
                props.onClose();
                setIsSubmitting(false);
            },
            (error: any) => {
                console.log(`ReportResultsInput -> onSubmitHandler -> error -> `, error);
                // TODO: Show the error in an error panel...
                // TODO: Nicer. Like a helper for getting the message from an error. Use an error interface that has code, error message, validation errors, etc...
                if (error.message) {
                    setError(error.message);
                }
                setIsSubmitting(false);
            });
    }

    return (
        <InlineDrawer
            className={styles.mainWrapper}
            open={isOpen}
            position="end"
            size="medium"
            separator>
            <DrawerHeader className={styles.background}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label={t('close')}
                            icon={<DismissRegular />}
                            onClick={props.onClose}
                        />
                    }>
                    {t('ppaReportCriteriaTitle')}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody className={styles.background}>
                <div className={styles.contentWrapper}>
                    {props.children}
                </div>
                {error &&
                    <div className={styles.errorWrapper}>{error}</div>}
                <div className={styles.buttonsWrapper}>
                    <Button
                        appearance="primary"
                        onClick={onSubmitHandler}
                        disabled={!props.canSubmit || isSubmitting}>{t('request')}</Button>
                    <Button
                        appearance="secondary"
                        onClick={props.onClose}>{t('cancel')}</Button>
                </div>
            </DrawerBody>
        </InlineDrawer>
    );
}