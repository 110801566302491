import { useContext } from "react";

// TODO: Fix this path!!
//import PageLayout from "@components/Layout/PageLayout";
import PageLayout from "../layout/page-layout";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


export default function Error404() {
  const { t } = useL10n<ILocaleStrings>();

  return (
    <PageLayout>
      <div style={{ margin: '1em 2em' }}>{t("notFound")}</div>
    </PageLayout>
  );
}
