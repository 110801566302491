import { MouseEventHandler, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItemRadio, MenuList, MenuPopover, MenuTrigger, Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, ToolbarProps, ToolbarRadioButton, ToolbarRadioGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    ClipboardTextLtrRegular, TextBulletListRegular, DocumentBulletListRegular
} from "@fluentui/react-icons";
import { IReportResultsToggleProps } from "./report-results-toggle.props";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";

const layoutStyles = makeStyles({

});

export default function ReportResultsToggle(props: IReportResultsToggleProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const menuItems = useMemo((): Array<any> => {
        console.log(`useMemo ->`);
        return [
            { value: "items", label: t('ppaReportViewItems'), icon: <TextBulletListRegular /> },
            { value: "summary", label: t('ppaReportViewSummary'), icon: <ClipboardTextLtrRegular /> }
        ]
    }, []);

    const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>({ reportResults: [props.view] });
    const [selectedItem, setSelectedItem] = useState<any>();

    const onChange: ToolbarProps["onCheckedValueChange"] = (e, { name, checkedItems }) => {
        console.log(`onChange ->`, e, name, checkedItems);
        setCheckedValues((record) => ({ ...record, [name]: checkedItems }));
    };

    useEffect(() => {
        const value = checkedValues["reportResults"][0];
        const x = menuItems.filter(m => m.value === value)[0];
        console.log(`useEffect ->`, value, x);
        setSelectedItem(x);
        props.onViewChange(x.value);
    }, [checkedValues]);

    return (
        <Menu>
            <MenuTrigger>
                <MenuButton
                    disabled={props.disabled}
                    icon={selectedItem?.icon}
                    appearance="subtle">{selectedItem?.label}</MenuButton>
            </MenuTrigger>
            <MenuPopover>
                <MenuList
                    checkedValues={checkedValues}
                    onCheckedValueChange={onChange}>
                    {menuItems.map((item: any, idx: number) => (
                        <MenuItemRadio
                            key={`reportResults-${item.value}`}
                            name="reportResults"
                            value={item.value}
                            icon={item.icon}>{item.label}</MenuItemRadio>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>);
}