import { makeStyles, shorthands } from "@fluentui/react-components";
//import NavigationCard from "./navigation-card";
import { INavigationCardsProps } from "./navigation-cards.props";
import Grid from '@mui/material/Unstable_Grid2';
import NavigationCard from "./navigation-card";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        ...shorthands.margin('1em', '1.5em'),
        maxWidth: '100em',
        '> div': {
            width: '100%'
        }
    }
});

export default function NavigationCards(props: INavigationCardsProps) {

    const styles = layoutStyles();

    // TODO: our own grid with our own breakpoints that match up with the Teams breakpoints...

    return (
        <div className={styles.mainWrapper}>
            <Grid container spacing={2}>
                {props.items2?.map((item, idx) => (
                    <Grid key={idx} xs={12} md={6} lg={4} >
                        <NavigationCard key={idx} item={item} />
                    </Grid>
                ))}
            </Grid>
        </div >
    );
}