import { useEffect, useState } from "react";
import { Field, ProgressBar, makeStyles, shorthands } from "@fluentui/react-components";
import { IReportProgressProps } from "./report-progress.props";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { getReportRequestStatusKey } from "lib/report-requests";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.margin('5em')
    }
});

// Interface for setting the progress only used in ths component.
interface IProgress {
    label: string;
    value: number | undefined;
    state: "none" | "success" | "error";
}

export default function ReportProgress(props: IReportProgressProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [progress, setProgress] = useState<IProgress>();

    useEffect(() => {
        let value: number | undefined = 0;
        let state: "none" | "success" | "error" = "none";
        switch (props.status) {
            case -1:
                break;
            case 0:
                break;
            case 1:
                value = props.percentageComplete === undefined ? undefined : props.percentageComplete / 100;
                break;
            case 2:
                value = undefined;
                break;
            case 3:
                value = 1;
                state = "success";
                break;
            case 4:
                value = 1;
                state = "error";
                break;
        }
        setProgress({
            label: t(getReportRequestStatusKey(props.status)),
            value: value,
            state: state,
        });
    }, [props.status, props.percentageComplete]);

    return (
        <div className={styles.mainWrapper}>
            {progress &&
                <Field validationMessage={progress.label} validationState={progress.state}>
                    <ProgressBar value={progress.value} />
                </Field>}
        </div>
    );
}