import { PropsWithChildren, useMemo } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IActionResultSectionProps } from "./action-result-section.props";
import CommandBar from "components/command-bar/command-bar";
import { ICommandBarItem } from "@interfaces/common.interfaces";
import {
    InfoRegular,
    FilterRegular,
    ArrowExportRegular,
    ArrowExportUpRegular,
    ArrowDownloadRegular,
    ReplayRegular,
    ArrowRepeat1Regular,
    ArrowRepeatAllRegular,
    ArrowImportRegular
} from "@fluentui/react-icons";
import { useLocation } from "react-router-dom";
import ActionResult from "../action-result/action-result";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});


const defaultCommandBarItems: Array<ICommandBarItem> = [
    {
        id: 'openFilter',
        placement: 'right',
        type: 'button',
        order: 1,
        disabled: true,
        icon: <FilterRegular />
    },
    // {
    //     id: 'openInput',
    //     placement: 'right',
    //     type: 'button',
    //     order: 2,
    //     icon: <ArrowImportRegular />
    // },
    // {
    //     id: 'resultsToggle',
    //     placement: 'right',
    //     type: 'component',
    //     order: 1,
    //     //disabled: true,
    //     //icon: <ReplayRegular />
    //     //ReportResultsToggle
    // },
    // // {
    // //     id: 'filterResults',
    // //     placement: 'right',
    // //     type: 'button',
    // //     order: 1,
    // //     icon: <FilterRegular />
    // // },
    // {
    //     id: 'export',
    //     placement: 'left',
    //     type: 'component',
    //     order: 1,
    //     //disabled: true,
    //     //component: <ExportReportButton columns={[]} items={[]} filenamePrefix="" />
    // },
    // {
    //     id: 'reportRequests',
    //     placement: 'left',
    //     type: 'component',
    //     order: 2,
    //     component: <ReportRequestsButton />
    // }
];

export default function ActionResultSection<T>(props: IActionResultSectionProps<T>) {
    const styles = layoutStyles();
    const location = useLocation();

    const commandBarItems = useMemo(() => {
        //console.log(`ReportSection -> useMemo -> commandbar -> `);
        var buttons = defaultCommandBarItems.map(item => {
            // //console.log(`ReportSection -> useMemo -> commandbar -> id -> `, item.id);
            // switch (item.id) {
            //     case 'openInput':
            //         return {
            //             ...item,
            //             onClick: () => {
            //                 setIsInputOpen(!isInputOpen);
            //             }
            //         }
            //     case 'resultsToggle':
            //         return {
            //             ...item,
            //             component: props.summaryComponent ?
            //                 <ReportResultsToggle disabled={!report} view={resultsView} onViewChange={(view: string) => { setResultsView(view) }} />
            //                 : null
            //         }
            //     case 'export':
            //         return {
            //             ...item,
            //             component: <ExportReportButton key={item.id} columns={exportColumns} items={report?.items} filenamePrefix={props.exportFilenamePrefix} />
            //         }
            //     // case 'reportRequests':
            //     //     return {
            //     //         ...item,
            //     //         component: <ReportRequestsButton />
            //     //     }
            // default:
            //     return item;
            return item;
        });

        // if (props.buttons) {
        //     var actionButtons = props.buttons.map<ICommandBarItem>((button): ICommandBarItem => {
        //         return {
        //             id: '1',
        //             placement: 'left',
        //             type: 'component',
        //             order: 10,
        //             component: cloneElement(button.component, { selectedItems: selectedItems, onClick: onActionButtonClick })
        //         }
        //     });

        //     buttons = buttons.concat(actionButtons);
        // }

        return buttons;
    }, [location.pathname]); //, isInputOpen, report, exportColumns, resultsView, selectedItems

    return (
        <>
            <CommandBar items={commandBarItems} />
            <section style={{ flexGrow: 1, display: 'flex', overflow: 'hidden', position: 'relative' }}>
                <ActionResult />

                {/* <ReportResults
                    columns={gridColumns}
                    columnOptions={getColumnOptions(gridColumns)}
                    requestStatus={status}
                    percentageComplete={percentageComplete}
                    loading={loadingReport}
                    report={report}
                    summaryComponent={props.summaryComponent}
                    noItemsMessage={props.noItemsMessage}
                    view={resultsView}
                    onSelectionChanged={(items: Array<TI>) => { setSelectedItems(items) }} /> */}

            </section>
        </>
    );
}