import { PropsWithChildren, useEffect, useState } from "react";
import { Avatar, Persona, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IPrincipal } from "@interfaces/common.interfaces";
import { IPrincipalsProps } from "./principals.props";
import Principal from "../principal/principal";

const layoutStyles = makeStyles({
    mainWrapper: {

    }
});

export default function Principals(props: IPrincipalsProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            {props.principals.map((principal, idx) => (
                <Principal key={idx} principal={principal} />
            ))}
        </div>);
}