import { PropsWithChildren, useEffect, useState } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IReportRequestsButtonProps } from "./report-requests-button.props";
import {
    HistoryRegular
} from "@fluentui/react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";

const layoutStyles = makeStyles({

});

export default function ReportRequestsButton(props: IReportRequestsButtonProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();
    const navigate = useNavigate();
    const location = useLocation();

    const [search, setSearch] = useState<string>();

    useEffect(() => {
        // TODO: This into a utility to split
        const attributes = new Array();

        // Split on forward slash and remove any empty items.
        const parts = location.pathname.split('/').filter(p => p);
        if (parts.length >= 2)
            attributes.push(`category=${parts[1]}`);
        if (parts.length >= 3)
            attributes.push(`report=${parts[2]}`);
        //
        const search = attributes.join('&');
        //
        setSearch(search);

        //console.log(parts);
    }, [location.pathname]);


    return (
        <ToolbarButton
            icon={<HistoryRegular />}
            disabled={false}
            onClick={() => navigate(`/report-requests?${search}`)} >{t('ppaRequests')}</ToolbarButton>
    );
}