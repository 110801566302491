import { PropsWithChildren, useEffect, useState } from "react";
import { Label, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IStorageInTenantReportSummaryProps } from "./storage-in-tenant-report-summary.props";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import { getSizeLabel } from "lib/utils";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1em'
    }
});

export default function StorageInTenantReportSummary(props: IStorageInTenantReportSummaryProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [additionalLabel, setAdditionalLabel] = useState<string>("");
    const [siteCountsLabel, setSiteCountsLabel] = useState<string>("");

    useEffect(() => {
        if (props.report?.details) {
            const used = getSizeLabel(props.report.details.sitesTotalSizeKB, 'KB');
            const quota = getSizeLabel(props.report.details.storageQuotaMB, 'MB');
            setAdditionalLabel(`(${t("ppaQuotaUsedValue", { used: used, quota: quota })})`);
            setSiteCountsLabel(t("ppaSiteCollectionsCountValue", { count: props.report.details.siteCount }));
        }
    }, [props.report?.details]);

    return (<div>
        {props.report?.details &&
            <>
                <div className={styles.section}>
                    <Label>{t("ppaPercentageOfQuota")}</Label>
                    <PercentageBar
                        value={props.report.details.percentageOfQuota}
                        valueDecimalPlaces={2}
                        showLabel={true}
                        additionalLabel={additionalLabel} />
                </div>
                <div className={styles.section}>
                    <Label>{t("ppaSiteCount")}</Label>
                    <span>{siteCountsLabel}</span>
                </div>
            </>}
    </div>);
}