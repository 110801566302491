import { PropsWithChildren, useContext } from "react";
import { Button, Card, CardFooter, CardHeader, Text, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular,
    ArrowReplyRegular,
    ShareRegular,
    ErrorCircleRegular
} from "@fluentui/react-icons";
import { IActionResultNoResultCardProps } from "./action-result-no-result-card.props";

const layoutStyles = makeStyles({
    mainWrapper: {
    },
    textWrapper: {
        ...shorthands.overflow('hidden'),
        '> span': {
            display: 'block',
            ...shorthands.overflow('hidden'),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    }
});

export default function ActionResultNoResultCard(props: IActionResultNoResultCardProps) {
    const styles = layoutStyles();

    return (
        <Card>
            <CardHeader
                header={<div className={styles.textWrapper}><Text as="span">{props.message}</Text></div>} />
            <CardFooter>
                {props.messages.length > 0 &&
                    <Button onClick={props.onMessagesClick} icon={<InfoRegular fontSize={16} />}></Button>}
                {props.errorCount > 0 &&
                    <Button onClick={props.onErrorsClick} icon={<ErrorCircleRegular fontSize={16} />}>{props.errorCount} Errors</Button>}
            </CardFooter>
        </Card>
    );
}