import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands,
    tokens,
    useTableColumnSizing_unstable,
    TableColumnSizingOptions,
    TableColumnDefinition,
    useTableFeatures,
    createTableColumn,
    TableCellLayout,
    useTableSelection,
    TableRowId,
    TableSelectionCell,
    SelectionHookParams,
    TableFeaturePlugin,
    Button
} from "@fluentui/react-components"
import { ITableColumn } from "@interfaces/common.interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IGridProps } from "./grid.props";
import {
    ChevronLeftRegular,
    ChevronRightRegular
} from "@fluentui/react-icons";
import GridPaging from "./grid-paging";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";


const layoutStyles = makeStyles({
    mainWrapper: {
        //flexGrow: 1,
        //...shorthands.padding('1em'),
    },
    table: {
        fontSize: tokens.fontSizeBase300
    },
    tableHeaderCell: {
        fontWeight: tokens.fontWeightSemibold
    }
});

//const defaultScreenPlaceHolderImage = "images/ReportPlaceholderImage.png";


export default function Grid<TI, TD>(props: IGridProps<TI, TD>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [columns, setColumns] = useState<TableColumnDefinition<TI>[]>([]);
    const [columnSizingOptions, setColumnSizingOptions] = useState<TableColumnSizingOptions>();
    const [items, setItems] = useState<Array<TI>>([]);
    const [allowSelection, setAllowSelection] = useState<boolean>(false);

    const [selectedRows, setSelectedRows] = useState(
        () => new Set<TableRowId>([])
    );

    // TODO: This could be a util method rather than in here...
    const getColumnOptions = (columns: Array<ITableColumn<TI>>): TableColumnSizingOptions => {
        var options: TableColumnSizingOptions = {};
        columns.forEach((column) => {
            options[column.key] = {
                idealWidth: column.idealWidth,
                minWidth: column.minWidth,
                defaultWidth: column.defaultWidth,
                //padding: 0
            }
        });
        return options;
    }

    useEffect(() => {
        var columnDefinitions: Array<TableColumnDefinition<any>> = props.columns.map((column) => {
            return createTableColumn<any>(
                {
                    columnId: column.key,
                    renderHeaderCell: () => column.renderHeaderCell ? column.renderHeaderCell() : <>{column.label}</>,
                    renderCell: (item: any) => column.renderCell ? column.renderCell(column.key, item, props.details) : <>{item[column.key]}</>,
                }
            );
        });
        setColumns(columnDefinitions);
        setColumnSizingOptions(getColumnOptions(props.columns));
    }, [props.columns]);

    useEffect(() => {
        console.log(`useEffect -> selectedRows ->`);
        if (props.onSelectionChanged) {

            const selectedItems: Array<TI> = [];

            // TODO: How does this look if the items are sorted??
            selectedRows.forEach((rowId) => {
                selectedItems.push(items[(rowId.valueOf() as number)]);
            });

            props.onSelectionChanged(selectedItems);
        }
    }, [selectedRows]);

    useEffect(() => {
        setAllowSelection((props.selectionMode && props.selectionMode !== 'none') === true);
    }, [props.selectionMode]);

    const {
        getRows,
        columnSizing_unstable,
        tableRef,
        selection: {
            allRowsSelected,
            someRowsSelected,
            toggleAllRows,
            toggleRow,
            isRowSelected,
        }
    } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableColumnSizing_unstable({ columnSizingOptions }),
            useTableSelection({
                selectionMode: props.selectionMode === 'multiselect' ? 'multiselect' : 'single',
                selectedItems: selectedRows,
                onSelectionChange: (e, data) => {
                    if (props.selectionMode === 'single') {
                        data.selectedItems.forEach((a) => {
                            selectedRows.has(a) ?
                                setSelectedRows(new Set([])) :
                                setSelectedRows(data.selectedItems)
                        });
                    }
                    else {
                        setSelectedRows(data.selectedItems);
                    }
                }
            })
        ]
    );

    const rows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => {
                toggleRow(e, row.rowId)
            },
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === " ") {
                    e.preventDefault();
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    const toggleAllKeydown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === " ") {
                toggleAllRows(e);
                e.preventDefault();
            }
        },
        [toggleAllRows]
    );

    return (
        <div className={styles.mainWrapper}>
            <Table
                ref={tableRef}
                className={styles.table}
                //size="small"
                {...columnSizing_unstable.getTableProps()}>
                <TableHeader>
                    <TableRow>
                        {allowSelection &&
                            <TableSelectionCell
                                hidden={props.selectionMode !== 'multiselect'}
                                checked={
                                    allRowsSelected ? true : someRowsSelected ? "mixed" : false
                                }
                                onClick={toggleAllRows}
                                onKeyDown={toggleAllKeydown}
                                checkboxIndicator={{ "aria-label": t('gridSelectAllRowsLabel') }} />}
                        {columns.map((column, idx) => (
                            <TableHeaderCell
                                key={column.columnId}
                                className={styles.tableHeaderCell}
                                {...columnSizing_unstable.getTableHeaderCellProps(
                                    column.columnId
                                )}>
                                <TableCellLayout truncate>
                                    {column.renderHeaderCell()}
                                </TableCellLayout>
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map(({ item, selected, onClick, onKeyDown, appearance }, idx) => (
                        <TableRow
                            key={idx}
                            onClick={allowSelection ? onClick : undefined}
                            onKeyDown={allowSelection ? onKeyDown : undefined}
                            appearance={allowSelection ? appearance : undefined}
                            aria-selected={selected}>
                            {allowSelection &&
                                <TableSelectionCell
                                    checked={selected}
                                    checkboxIndicator={{ "aria-label": t('gridSelectSingleRowLabel') }} />}
                            {columns.map((column, idx) => (
                                <TableCell
                                    key={column.columnId}
                                    {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                    <TableCellLayout truncate>
                                        {column.renderCell(item)}
                                    </TableCellLayout>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <GridPaging items={props.items} setItems={(items: Array<TI>) => setItems(items)} />
        </div>
    );
}