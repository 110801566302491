import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IWorkspaceLinkCellProps } from "./workspace-link-cell.props";
import { useContext, useEffect, useState } from "react";
import { getSharePointTemplateKey } from "lib/workspaces";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function WorkspaceLinkCell(props: IWorkspaceLinkCellProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [label, setLabel] = useState<string>(props.name);

    // TEMP:
    // const getTemplateNameKey = (template: string): string => {
    //     return `sharePointSiteTemplate${template?.replace('#', '')}Name`;
    // }

    useEffect(() => {
        if (props.name) {
            setLabel(props.name);
        }
        else {
            setLabel(t(getSharePointTemplateKey(props.template)));
        }
    }, [props.name, props.template]);

    return (<div className={styles.mainWrapper}>
        <Link href={props.url} appearance="subtle" target="_blank">{label}</Link>
    </div>);
}