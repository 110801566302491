import { PropsWithChildren, cloneElement, createElement, useContext, useEffect, useMemo, useState } from "react";
import { Button, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { usePPA } from "context/ppa-context";
import { TeamsFxContext } from "context/teams-context";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { IActionProps } from "./action.props";
import { IActionDefinition, IActionRequest, IActionResult } from "@interfaces/actions/actions-common.interfaces";
import ReportProgress from "components/reports-common/report-progress/report-progress";
import { getActionFormComponent, getActionResultComponent } from "lib/actions";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";

const layoutStyles = makeStyles({
    mainWrapper: {
        //...shorthands.borderWidth('1px', '0'),
    },
    contentWrapper: {
        ...shorthands.padding('1em', 0)
    },
    errorWrapper: {

    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '> button': {
            ...shorthands.margin(0, 0, 0, '1em')
        }
    }
});

//PropsWithChildren<any>
export default function Action<T>(props: IActionProps<T>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const { getApiClient } = usePPA();


    //actionDefinition: IActionDefinition<T>;
    const [actionDefinition, setActionDefinition] = useState<IActionDefinition<T> | undefined>(props.actionDefinition);

    const [view, setView] = useState<string>("loading"); // loading, form, progress, result

    const [status, setStatus] = useState<number>(-1); // TODO: Enum??
    const [percentageComplete, setPercentageComplete] = useState<number>(0); // Maybe something else...
    const [requestId, setRequestId] = useState<string>();
    const [requested, setRequested] = useState<string>();

    const [checkCount, setCheckCount] = useState<number>(0);

    // // Create the api client for each of the api calls.
    // const apiClient = useMemo(() => {
    //     //console.log(`ReportSection -> useMemo -> createApiClient -> `);
    //     return createApiClient(
    //         ppa.appInstanceApiUrl || "",
    //         new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
    //     );
    // }, [ppa.appInstanceApiUrl]);

    // TODO: On load...
    // if props.actionRequestId - Get the request status.
    // else - show the form
    // 

    useEffect(() => {
        console.log(`Action -> useEffect -> [] ->`);
        if (props.actionRequestId) {
            console.log(`Action -> useEffect -> [] -> has action request id`, props.actionRequestId);
            setRequestId(props.actionRequestId);
            getRequestStatus(props.actionRequestId);
        }
        else {
            setView("form");
        }
    }, []);

    //
    useEffect(() => {
        console.log(`Action -> useEffect -> requestId, status, checkCount ->`, requestId, status, checkCount, requested);
        var timerId: NodeJS.Timeout;
        if (requestId && status > -1) {
            // TODO: timedOut -> use requested and add xx seconds to see if that's < now.
            const timedOut = false;
            if (status !== 3 && status !== 4) {

                //
                setView("progress");

                if (timedOut) {
                    // TODO: Something...
                }
                else {
                    timerId = setTimeout(() => {
                        getRequestStatus(requestId);
                    }, 1000);
                }
            }
            else if (status === 3) {
                //console.log(`ReportSection -> useEffect -> GET THE REPORT -> `);

                // TODO: This check really needs to see if the report has been retrieved and stop...
                setView("result");
                //getResult(requestId);
            }
        }
        return () => clearTimeout(timerId);
    }, [requestId, status, checkCount]);

    const requestAction = async (url: string, requestModel: any): Promise<IActionRequest> => { //IActionRequest
        try {
            console.log(`Action -> requestAction ->`, props.reportRequestId, url, requestModel);
            clearResults();

            //
            requestModel.reportRequestId = props.reportRequestId;
            const response = await getApiClient().post<IActionRequest>(url, requestModel); // TODO: Type //IReportRequest
            setRequested(response.data.requested);
            setRequestId(response.data.requestId);
            setStatus(response.data.status);
            setView("progress");
            if (props.onRequestCallback) {
                props.onRequestCallback(response.data);
            }
            return response.data;
        }
        catch (ex: unknown) {
            console.log(`Action -> requestAction -> catch ->`, ex);
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    const getRequestStatus = async (requestId: string): Promise<IActionRequest> => {
        try {
            const response = await getApiClient().get<IActionRequest>(`/api/action-requests/${requestId}`);
            //console.log(`ReportSection -> getRequestStatus -> data -> `, response.data);

            //
            if (!actionDefinition) {
                setActionDefinition({
                    actionType: response.data.actionType,
                    form: getActionFormComponent(response.data.actionType),
                    result: getActionResultComponent(response.data.actionType)
                });
            }

            setRequested(response.data.requested);
            setStatus(response.data.status);
            setPercentageComplete(response.data.percentageComplete);
            setCheckCount(checkCount + 1);
            return response.data;
        }
        catch (ex: unknown) {
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    // IActionResult<any> // T??
    const getResult = async (url: string): Promise<IActionResult<any>> => { // Any needs to be TResult?? // WAS: IReport<TI, TD> 
        try {
            console.log(`Action -> getResult ->`, url);
            //setLoadingReport(true);
            const response = await getApiClient().get<IActionResult<any>>(url); // WAS: IReport<TI, TD>
            //setReport(response.data);
            //setLoadingReport(false);
            return response.data;
        }
        catch (ex: unknown) {
            //setLoadingReport(false);
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    const cancelAction = () => {
        if (props.onCancel)
            props.onCancel();
    }

    const clearResults = () => {
        setCheckCount(0);
        setStatus(-1);
        setPercentageComplete(0);
        //setLoadingReport(false);
        setRequestId(undefined);
        setRequested(undefined);
        //setReport(undefined);
        //setSelectedItems([]);
    }

    return (
        <div className={styles.mainWrapper}>
            {view === "loading" &&
                <div>{t('loading')}</div>}
            {view === "form" && actionDefinition &&
                <div>{cloneElement(actionDefinition.form, { model: props.actionDefinition?.model, onSubmit: requestAction, onCancel: cancelAction })}</div>}
            {view === "progress" &&
                <ReportProgress status={status} percentageComplete={percentageComplete} />}
            {view === "result" && actionDefinition &&
                <div>{cloneElement(actionDefinition.result, { requestId: requestId, getResult: getResult })}</div>}
        </div>);
}