import { PropsWithChildren, createElement } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IIconWithLabelProps } from "./icon-with-label.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    iconWrapper: {

    },
    labelWrapper: {
        ...shorthands.padding('0', '0', '0', '0.25em')
    },
    primaryLabel: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold
    },
    secondaryLabel: {
        fontSize: tokens.fontSizeBase200
    }
});

export default function IconWithLabel(props: IIconWithLabelProps) {
    const styles = layoutStyles();

    const icon = createElement(props.icon);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.iconWrapper}>
                {icon}
            </div>
            <div className={styles.labelWrapper}>
                <div className={styles.primaryLabel}>{props.label}</div>
                {props.secondaryLabel &&
                    <div className={styles.secondaryLabel}>{props.secondaryLabel}</div>}
            </div>
        </div>
    );
}