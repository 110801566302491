import { PropsWithChildren, useEffect, useState } from "react";
import {
    Body1Stronger,
    Button,
    Caption1,
    Card,
    CardHeader,
    tokens,
    makeStyles,
    shorthands,
    Spinner
} from "@fluentui/react-components";
import {
    OpenRegular,
    ChevronRightRegular
} from "@fluentui/react-icons";
import { ISignupAppInstanceProps } from "./signup-app-instance.props";
import axios, { AxiosError } from "axios";
import { IAppInstance } from "@interfaces/appInstance.interfaces";
import { ISignup } from "@interfaces/signup.interfaces";
import { differenceInSeconds, parseISO } from "date-fns";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        maxWidth: '600px',
        marginBottom: '1.5em'
    },
    error: {
        color: tokens.colorStatusDangerForeground1
    }
});

export default function SignupAppInstance(props: ISignupAppInstanceProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorDescription, setErrorDescription] = useState<string>();
    const [showError, setShowError] = useState<boolean>(false);

    const [selected, setSelected] = useState<string>('');
    const [retryCount, setRetryCount] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    //const [responseCode, setResponseCode] = useState<number>(0);

    //
    const setSubmitting = (submitting: boolean) => {
        setIsSubmitting(submitting);
        props.setSubmitting(submitting);
    }

    const setError = (error: unknown | AxiosError<unknown>): void => {
        if (axios.isAxiosError(error)) {
            let errorDescriptionHtml = "";
            if (error.message)
                setErrorMessage(error.message);
            else
                setErrorMessage(`An error occurred`);
            if (error.response?.data?.validationErrors) {
                console.log(`error -> validationErrors ->`, error.response?.data?.validationErrors);
                // TODO: Proper validation error type

                (error.response?.data?.validationErrors as Array<any>).forEach((error: any) => {
                    errorDescriptionHtml += `${error.message}</br>`;
                });
            }
            if (error.response?.data?.errorMessages) {
                console.log(`error -> errorMessages ->`, error.response?.data?.errorMessages);
                (error.response?.data?.errorMessages as Array<any>).forEach((error: any) => {
                    errorDescriptionHtml += `${error.message}</br>`;
                });
            }
            setErrorDescription(errorDescriptionHtml);
        }
        else {
            console.log(`SignupAppInstance -> setError -> Not Axios error ->`, error);
        }
    }

    const onSelect = () => {
        console.log(`SignupAppInstance -> onSelect -> `, props.appInstance);
        //
        setErrorMessage('');
        setErrorDescription('');
        setShowError(false);

        setSelected(new Date().toISOString());
        setRetryCount(0);
        //setResponseCode(0);

        setSubmitting(true);
        //
        setAppInstanceWithRetry();
    }

    const setAppInstanceWithRetry = (): void => {
        props.onSelect(props.appInstance).then(
            (data: any) => {
                setRetryCount(0);
                setSubmitting(false);
            },
            (error: any) => {
                console.log(`SignupAppInstance -> setAppInstance -> error -> `, error);

                if (error.response?.status === 401) {
                    setRetryCount(retryCount + 1);
                    setError(error);
                }
                else {
                    setError(error);
                    setShowError(true);
                    setSubmitting(false);
                }
            }
        );
    }

    useEffect(() => {
        console.log(`SignupAppInstance -> useEffect -> `, retryCount, selected);
        var timerId: NodeJS.Timeout;
        if (selected && retryCount > 0) {
            console.log(`SignupAppInstance -> useEffect -> retry ->`);
            const selectedDateTime = parseISO(selected);
            const diff = differenceInSeconds(new Date(), selectedDateTime);
            const timedOut = diff > 30;
            console.log(`SignupAppInstance -> useEffect -> retry -> diff ->`, diff);
            if (timedOut) {
                setShowError(true);
                setSubmitting(false);
            }
            else {
                timerId = setTimeout(() => {
                    console.log(`SignupAppInstance -> useEffect -> retry ->`);
                    setAppInstanceWithRetry();
                }, 2000);
            }
        }
        return () => clearTimeout(timerId);
    }, [selected, retryCount]);


    return (
        <Card className={styles.mainWrapper}>
            <CardHeader
                header={<Body1Stronger>{props.appInstance.name}</Body1Stronger>}
                description={<Caption1>{props.appInstance.description}</Caption1>}
                action={<Button
                    onClick={onSelect}
                    disabled={props.disabled}
                    icon={isSubmitting ? <Spinner size="extra-tiny" /> : <ChevronRightRegular />}
                    iconPosition="after">{t("select")}</Button>} />
            {showError && errorMessage && <div className={styles.error}>{errorMessage}</div>}
            {showError && errorDescription && <div className={styles.error} dangerouslySetInnerHTML={{ __html: errorDescription }}></div>}
        </Card>
    );
}