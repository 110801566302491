import { PropsWithChildren } from "react";
import { ICommandBarProps } from "./command-bar.props";
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular
} from "@fluentui/react-icons";
import CommandBarGroup from "./command-bar-group";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.borderWidth('0', '0', '1px', '0'),
        ...shorthands.borderStyle('solid', 'none'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.padding('0', '2em')
    },
    toolbar: {
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'space-between'
    }
});

export default function CommandBar(props: PropsWithChildren<ICommandBarProps>) {

    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            <Toolbar className={styles.toolbar}>
                <CommandBarGroup items={props.items?.filter(i => i.placement === 'left')} />
                <CommandBarGroup items={props.items?.filter(i => i.placement === 'right')} />
            </Toolbar>
        </div>
    );
}