import { Body1, Image, Subtitle1, makeStyles } from "@fluentui/react-components";
import { IScreenPlaceholderProps } from "./screen-placeholder.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5em'

    },
    text: {
        maxWidth: '50%',
        textAlign: 'center'
    }
});

export default function ScreenPlaceholder(props: IScreenPlaceholderProps) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            <Image src={props.imageUrl} />
            <Subtitle1 className={styles.text}>{props.title}</Subtitle1>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: props.message }} />
        </div>
    );
}